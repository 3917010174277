<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("mp.sub") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("mp.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onTambah"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("mp.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('mp.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Role">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="role in optionRole1"
                        :key="role.id"
                      >
                        <label class="form-radio-label">
                          <input
                            class="form-radio-item"
                            type="radio"
                            :value="role"
                            v-model="filterRole"
                          />
                          {{ role }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRole === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="matpros"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('mp.headers[1]'),
                  key: 'type'
                },
                {
                  label: $t('mp.headers[2]'),
                  key: 'produk'
                },
                {
                  label: $t('mp.headers[3]'),
                  key: 'stock'
                },
                {
                  label: $t('mp.headers[4]'),
                  key: 'actions'
                }
              ]"
              sort-icon-left
              show-empty
              small
              responsive="true"
              class="simple-master-table tbl-matpro"
            >
              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++data.index + (currPage - 1) * perPage }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(type)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    {{ data.item.jenisMatpros.matpro }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(produk)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    {{ data.item.produks.brand }}
                  </li>
                  <br />
                </ul>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-add-fill ri-lg ri-mid action-btn"
                      @click="addStock(item)"
                    ></i>
                    <i
                      class="ri-subtract-fill ri-lg ri-mid action-btn"
                      @click="reduceStock(item)"
                    ></i>
                    <i
                      class="ri-edit-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <!-- <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i> -->
                  </div>
                </ul>
              </template>
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-matpro" centered hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <BaseInput :blabel="$t('mp.label.type')" v-if="!isStockEdit">
            <custom-select
              :placeholder="$t('mp.placeholder.type')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.matpro.$error
              }"
              v-model="$v.formTambah.matpro.$model"
              :options="matproTypes"
              label="matpro"
            >
            </custom-select>
            <span
              class="text-feedback text-red small"
              v-if="$v.formTambah.matpro.$error"
            >
              {{ $t("feedback.required") }}
            </span>
          </BaseInput>
          <BaseInput :blabel="$t('mp.label.product')" v-if="!isStockEdit">
            <custom-select
              :placeholder="$t('mp.placeholder.product')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.produk.$error
              }"
              v-model="formTambah.produk"
              :options="dataProduk"
              label="brand"
            >
            </custom-select>
            <span
              v-if="$v.formTambah.produk.$error"
              class="text-feedback text-red small"
            >
              {{ $t("feedback.required") }}
            </span>
          </BaseInput>
          <div
            v-if="isStockEdit && formTambah.currentStock !== null"
            class="mb-2"
          >
            <span style="font-weight: 600;">
              Stok Saat Ini : {{ formTambah.currentStock }}
            </span>
          </div>
          <BaseInput
            v-if="!isEdit || isStockEdit"
            type="number"
            :blabel="$t('mp.label.stock')"
            :placeholder="$t('mp.placeholder.stock')"
            v-model="$v.formTambah.stock.$model"
            :error="$v.formTambah.stock.$error"
            :feedback="$t('feedback.required')"
          />
          <button
            v-if="isStockEdit && formTambah.currentStock === null"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("mp.add_stock") }}
          </button>
          <button
            v-else-if="isStockEdit && formTambah.currentStock !== null"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("mp.redc_stock") }}
          </button>
          <button
            v-else
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card, BaseInput } from "@/components/index";
import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { requiredIf } from "vuelidate/lib/validators";
import * as XLSX from "xlsx";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import { Q_LIST_PRODUK, listMatproTypes, listMatpros } from "@/graphql/queries";
import {
  addMatproStock,
  reduceMatproStock,
  createMatpro,
  deleteMatpro,
  updateMatpro
} from "@/graphql/mutations";

export default {
  components: {
    Card,
    BaseInput,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          label: "#",
          key: "index"
        },
        {
          label: "Jenis Matpro",
          key: "type"
        },
        {
          label: "Produk",
          key: "produk"
        },
        {
          label: "Stok",
          key: "stock"
        },
        {
          label: "Action",
          key: "actions"
        }
      ],

      matpros: [],
      dataProduk: [],
      matproTypes: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      modalTitle: "",
      isEdit: false,
      isStockEdit: false,
      editStockType: "",
      formTambah: {
        matpro: "",
        produk: "",
        stock: null
      }
    };
  },
  validations: {
    formTambah: {
      matpro: {
        required: requiredIf(function() {
          return !this.isStockEdit;
        })
      },
      produk: {
        required: requiredIf(function() {
          return !this.isStockEdit;
        })
      },
      stock: {
        required: requiredIf(function() {
          return !this.isEdit;
        })
      }
    }
  },
  apollo: {
    listMaterialPromosi: {
      query: () => listMatpros,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          jenisMatproId: "",
          produkId: "",
          orderBy: "UPDATED_AT",
          sortBy: "DESC"
        };
      },
      debounce: 500,
      result({ data }) {
        this.matpros = data.listMaterialPromosi.matpros;
        this.totalPages = data.listMaterialPromosi.totalPage;
        this.totalData = data.listMaterialPromosi.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listJenisMaterialPromosi: {
      query: () => listMatproTypes,
      variables() {
        return {
          page: 1,
          limit: 999,
          keyword: "",
          orderBy: "MATPRO",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.matproTypes = data.listJenisMaterialPromosi.jenismatpros;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 9999,
          keyword: ""
        };
      },
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
      }
    }
  },
  methods: {
    onTambah() {
      this.modalTitle = this.$t("mp.add");
      this.isEdit = false;
      this.resetForm();
      this.$bvModal.show("modal-matpro");
    },
    onImport() {
      this.$bvModal.show("modal-import-petani");
    },
    importCrop(e) {
      e.preventDefault();
      let formData = new FormData();
      formData.append("file", this.dataImport);
      axios
        .post(`${process.env.VUE_APP_URL_GRAPHQL}/produk/import`, formData, {
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response) {
            this.$bvModal.hide("modal-import-produk");
            this.dataImport = null;
            this.$apollo.queries.listProduk.refetch();
            this.$swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Import Produk Berhasil",
              width: 400,
              timer: 1500
            });
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err.message,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    generateTemplate() {
      const wb = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet([{}]);
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Kategori",
            "Nama Bahan",
            "Jumlah Terkecil",
            "Satuan Terkecil",
            "Harga / Satuan Terkecil"
          ]
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { width: 8 },
        { width: 20 },
        { width: 14 },
        { width: 14 },
        { width: 22 }
      ];
      XLSX.utils.book_append_sheet(wb, worksheet, `Sheet 1`);
      XLSX.writeFile(wb, `Template Import User.xlsx`);
    },
    onEdit(data) {
      this.resetForm();
      this.modalTitle = `Edit ${data.jenisMatpros.matpro} - ${data.produks.brand}`;
      this.isEdit = true;

      this.formTambah.id = data.id;
      this.formTambah.matpro = data.jenisMatpros;
      this.formTambah.produk = data.produks;
      this.formTambah.stock = data.stock;
      this.$bvModal.show("modal-matpro");
    },
    addStock(data) {
      this.resetForm();
      this.isEdit = false;
      this.isStockEdit = true;
      this.editStockType = "add";
      this.modalTitle = `${this.$t("mp.add_stock")} ${
        data.jenisMatpros.matpro
      } - ${data.produks.brand}`;
      this.formTambah.id = data.id;
      this.$bvModal.show("modal-matpro");
    },
    reduceStock(data) {
      this.resetForm();
      this.isEdit = false;
      this.isStockEdit = true;
      this.editStockType = "reduce";
      this.modalTitle = `${this.$t("mp.redc_stock")} ${
        data.jenisMatpros.matpro
      } - ${data.produks.brand}`;
      this.formTambah.id = data.id;
      this.formTambah.currentStock = data.stock;
      this.$bvModal.show("modal-matpro");
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.formTambah = {};
      this.isEdit = false;
      this.isStockEdit = false;
      this.editStockType = "";
      this.formTambah.currentStock = null;
      this.$v.$reset();
    },
    closeModal() {
      this.$bvModal.hide("modal-matpro");
      this.formTambah.matpro = "";
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isStockEdit && this.editStockType === "add") {
          return new Promise((resolve, reject) => {
            try {
              this.$apollo
                .mutate({
                  mutation: addMatproStock,
                  variables: {
                    id: this.formTambah.id,
                    stock: parseInt(this.formTambah.stock)
                  }
                })
                .then(() => {
                  this.$apollo.queries.listMaterialPromosi.refetch();
                  this.closeModal();
                  this.toastAlert("Berhasil menambahkan stok");
                  resolve();
                });
            } catch (e) {
              reject(e);
            }
          });
        } else if (this.isStockEdit && this.editStockType === "reduce") {
          return new Promise((resolve, reject) => {
            try {
              this.$apollo
                .mutate({
                  mutation: reduceMatproStock,
                  variables: {
                    id: this.formTambah.id,
                    stock: parseInt(this.formTambah.stock)
                  }
                })
                .then(() => {
                  this.$apollo.queries.listMaterialPromosi.refetch();
                  this.closeModal();
                  this.toastAlert("Berhasil mengurangi stok");
                  resolve();
                });
            } catch (e) {
              reject(e);
            }
          });
        } else if (this.isEdit) {
          return new Promise((resolve, reject) => {
            const editMatpro = this.$apollo.mutate({
              mutation: updateMatpro,
              variables: {
                id: this.formTambah.id,
                jenisMatproId: this.formTambah.matpro
                  ? this.formTambah.matpro.id
                  : "",
                produkId: this.formTambah.produk
                  ? this.formTambah.produk.id
                  : ""
              }
            });
            if (resolve) {
              resolve(editMatpro);
            } else {
              reject(editMatpro);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModal();
                this.$apollo.queries.listMaterialPromosi.refetch();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addMatpro = this.$apollo.mutate({
              mutation: createMatpro,
              variables: {
                jenisMatproId: this.formTambah.matpro
                  ? this.formTambah.matpro.id
                  : "",
                produkId: this.formTambah.produk
                  ? this.formTambah.produk.id
                  : "",
                stock: parseInt(this.formTambah.stock)
              }
            });
            if (resolve) {
              resolve(addMatpro);
            } else {
              reject(addMatpro);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.save"));
                this.closeModal();
                this.$apollo.queries.listMaterialPromosi.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await new Promise((resolve, reject) => {
              const delTanaman = this.$apollo.mutate({
                mutation: deleteMatpro,
                variables: {
                  id: id
                }
              });
              if (resolve) {
                resolve(delTanaman);
              } else {
                reject(delTanaman);
              }
            });
            if (response) {
              this.toastAlert(this.$t("alert.delete"));
              this.$apollo.queries.listMaterialPromosi.refetch();
            }
          } catch (err) {
            this.errorAlert(err);
          }
        }
      });
    },
    // async     async handleExport() {
    // this.isLoading = true;
    //   try {
    //     const result = await this.$apollo.query({
    //       query: listMatpros,
    //       variables: {
    //         page: 1,
    //         limit: 9999999,
    //         keyword: this.filterInput,
    //         jenisMatproId: "",
    //         produkId: "",
    //         orderBy: "UPDATED_AT",
    //         sortBy: "DESC"
    //       }
    //     });
    //     const list = result.data.listMaterialPromosi.matpros;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = ["#", "Matpro", "Produk", "Stok"];
    //       const filterVal = ["index", "jenisMatpros", "produks", "stock"];
    //       setTimeout(() => {
    //         list.map((a, b) => {
    //           a.index = b + 1;
    //           if (a.jenisMatpros) {
    //             a.jenisMatpros = a.jenisMatpros.matpro;
    //           }
    //           if (a.produks) {
    //             a.produks = a.produks.brand;
    //           }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    // }
    async handleExport() {
      this.isLoading = true;
      await axios
        .get(process.env.VUE_APP_URL_GRAPHQL + "/matpro/excel", {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `matpro-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style>
.tbl-matpro tr td:nth-child(2) {
  width: 35%;
}
.tbl-matpro tr td:nth-child(3) {
  width: 35%;
}
.tbl-matpro tr td:nth-child(4) {
  width: 10%;
}
</style>
